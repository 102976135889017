// Images
import NETFLIXGPT from "../../assets/netflixgpt.png";
import REPLIT from "../../assets/replit.png";
import RESUME from "../../assets/MANAN_SHAH.pdf";
import PERSONAL_PIC from "../../assets/PROFILE_PIC.png";
import VIDEO_PLAY from "../../assets/video_play.png";
import DEV_DETECTIVE from "../../assets/dev_detective.png";
import CERTIFICATES from "../../assets/certificates.png";

export const NETFLIXGPT_PNG = NETFLIXGPT;
export const REPLIT_PNG = REPLIT;
export const PROFILE_PIC = PERSONAL_PIC;
export const VIDEO_PLAY_PNG = VIDEO_PLAY;
export const DEV_DETECtIVE_PNG = DEV_DETECTIVE;
export const CERTIFICATES_PNG = CERTIFICATES;

// Resume
export const MANAN_SHAH = RESUME;

// Tech stack
export const TECHNOLOGIES = [
  "JavaScript, TypeScript",
  "ReactJs",
  "Node.js",
  "ExpressJs",
  "Docker",
  "Container Orchestration (Kubernetes)",
  "AWS (RDS, EC2, ElasticIP, S3)",
  "MongoDB",
  "MySql",
  "Postgres SQL",
  "Java",
  "C, C++",
  "ReactNative",
  "NextJs",
];

// Base URL
export const BASE_URL = "https://portfolio-backend-b8j4.onrender.com";
