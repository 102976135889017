// import Work2 from "../../assets/cryptoverse.png";
// import Work3 from "../../assets/travel.png";
// import Work4 from "../../assets/blog-1.svg";
import {
  CERTIFICATES_PNG,
  DEV_DETECtIVE_PNG,
  NETFLIXGPT_PNG,
  REPLIT_PNG,
  VIDEO_PLAY_PNG,
} from "../constants/constants";

const Menu = [
  {
    id: 1,
    image: NETFLIXGPT_PNG,
    title: "Netflix-GPT",
    url: "https://movies.shahmanan.tech/",
    repositoryUrl: "https://github.com/manan228/Netflix-GPT",
  },
  {
    id: 2,
    image: REPLIT_PNG,
    title: "Repl.it",
    url: "https://replit.shahmanan.tech/",
    repositoryUrl: "https://github.com/manan228/repl.it",
  },
  {
    id: 3,
    image: VIDEO_PLAY_PNG,
    title: "Youtube Clone: Video Play",
    url: "https://videoplay.shahmanan.tech/",
    repositoryUrl: "https://github.com/manan228/YoutubeClone-Video_Play",
  },
  {
    id: 4,
    image: DEV_DETECtIVE_PNG,
    title: "Dev Detective",
    url: "https://devdetective.shahmanan.tech/",
    repositoryUrl: "https://github.com/manan228/devDetective",
  },
  {
    id: 5,
    image: CERTIFICATES_PNG,
    title: "Certificates",
    url: "https://certificates.shahmanan.tech/",
    repositoryUrl: "https://github.com/manan228/Certificates",
  },
  // {
  //   id: 2,
  //   image: Work2,
  //   title: "Cryptoverse",
  //   url: "https://cryptoverse.gregsithole.com",
  //   repositoryUrl: "https://github.com/GregSithole/crypto-app",
  // },
  // {
  //   id: 3,
  //   image: Work3,
  //   title: "Travel Advisor",
  //   category: ["Frontend"],
  //   url: "https://travel.gregsithole.com",
  //   repositoryUrl: "https://github.com/GregSithole/travel-advisor",
  // },
  // {
  //   id: 4,
  //   image: Work4,
  //   title: "Wallhaven CLI",
  //   category: ["Backend"],
  //   url: "https://www.npmjs.com/package/wallhaven-cli",
  //   repositoryUrl: "https://github.com/GregSithole/wallhaven-cli",
  // },
];

export default Menu;
